<template>
	<!-- begin login -->
	<div class="login login-with-news-feed">
		<!-- begin news-feed -->
		<div class="news-feed bg-purple-transparent-2">
			<div class="text-center pb-3" style="margin-top: 160px;">
				<img :src="logoSumut" width="200" alt="logoSumut">
			</div>
			<div>
				<h3 class="d-flex justify-content-center">PEMERINTAH PROVINSI SUMATERA UTARA</h3>
				<h3 class="d-flex justify-content-center">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</h3>
				<p class="font-weight-bold d-flex justify-content-center" style="font-size: 15px">Jalan Serbaguna No. 10 Helvetia, Medan</p>
			</div>
		</div>
		<!-- end news-feed -->
		<!-- begin right-content -->
		<div class="right-content">
			<!-- begin login-header -->
			<div class="login-header">
				<div class="brand text-center">
					<!-- <span>
						<img :src="logoSumut" width="60" alt="logoSumut">
					</span> --> Lupa Password
					<small>Silahkan masukkan alamat email dan kami akan mengirimkan anda sebuah intruksi untuk merubah password anda.</small>
				</div>
				<div class="icon">
					<i class="fa fa-sign-in"></i>
				</div>
			</div>
			<!-- end login-header -->
			<!-- begin login-content -->
			<b-alert variant="danger" show v-if="message">
				{{ message }}
			</b-alert>
           <alert-error :form="form" class="border-danger mb-2" v-else></alert-error>
			<div class="login-content">
				<form class="margin-bottom-0" @submit.prevent="store" @keydown="form.onKeydown($event)"
                autocomplete="off">
					<div class="form-group m-b-15">
						<input type="email" v-model="form.email" :class="{'form-control': true, 'is-invalid': errors.hasOwnProperty('email'),}" placeholder="Alamat Email" :disabled="isDisable" />
						<em
							v-if="errors.hasOwnProperty('email')"
                            class="form-text text-danger"
						>
							{{ errors.email[0]}}
						</em>
					</div>
					<div class="login-buttons">
						<button type="submit" class="btn btn-danger btn-block btn-lg" :disabled="isDisable">
							<span v-if="isLoading">
								<b-spinner
                                    variant="light"
                                    small
                                    label="loading"
                                ></b-spinner>
							</span>
							<i v-else class="fa fa-paper-plane"></i>
							Kirim
						</button>
                        <p class="mt-2">
                            Kembali ke halaman
                            <span><router-link to="/auth/login" class="font-weight-bold">Login</router-link></span>
                        </p>
					</div>
					<hr />
					<p class="text-center text-grey-darker">
						&copy; Pemerintah Provinsi Sumatera Utara | All Right Reserved 2020
					</p>
				</form>
			</div>
			<!-- end login-content -->
		</div>
		<!-- end right-container -->
	</div>
	<!-- end login -->
</template>

<script>
/* eslint-disable */
    // eslint-disable-next-line
    // eslint-disable vue/no-unused-vars
    // eslint-disable-line no-unused-vars

import PageOptions from '@/config/PageOptions.vue'
import Logo from '@/assets/images/logoSumut.png'

export default {
	data () {
		return {
			logoSumut: Logo,
			form: new Form({
				email: '',
			}),
			errors: [],
			isLoading: false,
			isDisable: false,
			message: ''
		}
	},
	created() {
		PageOptions.pageEmpty = true;
		document.body.className = 'bg-white';
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		document.body.className = '';
		next();
    },
    methods: {
		store () {
			this.isLoading = true
			this.isDisable = true
			this.form.post("/api/password/forgot").then(response => {
				this.isLoading = false
				this.isDisable = false
				this.errors = []
				if (this.form.successful) {
					this.$swal({
						title: 'PEMBERITAHUAN',
						text: "Intruksi Reset Password Telah Dikirim ke Email Anda.",
						icon: 'success',
						showCancelButton: false,
						showCloseButton: true,
						buttonsStyling: false,
						confirmButtonText: "OKE",
						confirmButtonClass: 'btn btn-primary',
						allowOutsideClick: false,
						timer: 7000
					})
					this.$router.push({ name: 'Login'})
				}
			}).catch(error => {
				this.isLoading = false
				this.isDisable = false
				console.log(error)
				if (error.response.status === 422) {
					this.errors = error.response.data
				} else {
					this.$swal({
						title: 'ERROR',
						icon: 'error',
						html: 'Terjadi Masalah. <br> Silahakan hubungi Administrator Pajak Daerah.',
						type: 'error',
						showCancelButton: false,
						showCloseButton: true,
						buttonsStyling: false,
						confirmButtonText: "OKE",
						confirmButtonClass: 'btn btn-danger',
						allowOutsideClick: false
					})
				}
			})
		},
    }
}
</script>