<template>
	<!-- begin login -->
	<div class="login login-with-news-feed">
		<!-- begin news-feed -->
		<div class="news-feed bg-purple-transparent-2">
			<div class="text-center pb-3" style="margin-top: 160px;">
				<img :src="logoSumut" width="200" alt="logoSumut">
			</div>
			<div>
				<h3 class="d-flex justify-content-center">PEMERINTAH PROVINSI SUMATERA UTARA</h3>
				<h3 class="d-flex justify-content-center">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</h3>
				<p class="font-weight-bold d-flex justify-content-center" style="font-size: 15px">Jalan Serbaguna No. 10 Helvetia, Medan</p>
			</div>
		</div>
		<!-- end news-feed -->
		<!-- begin right-content -->
		<div class="right-content">
			<!-- begin login-header -->
			<div class="login-header">
				<div class="brand text-center">
					<!-- <span>
						<img :src="logoSumut" width="60" alt="logoSumut">
					</span> --> Reset Password
					<small>Untuk menjaga akun anda tetap aman.</small>
					<small>Gunakan password minimal 8 karakter.</small>
				</div>
				<div class="icon">
					<i class="fa fa-sign-in"></i>
				</div>
			</div>
			<!-- end login-header -->
			<!-- begin login-content -->
            <b-alert variant="danger" show v-if="message">
				{{ message }}
			</b-alert>
			<alert-error :form="form" class="border-danger mb-2" v-else></alert-error>
			<div class="login-content">
				<form class="margin-bottom-0" @submit.prevent="store" @keydown="form.onKeydown($event)"
                autocomplete="off">
					<label class="control-label">Email <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<input type="email" v-model="form.email" :class="{'form-control': true, 'is-invalid': form.errors.has('email'),}" placeholder="Masukkan password baru" />
							<em
							v-if="form.errors.has('email')"
                            class="form-text text-danger"
						>
							{{ errors.email[0]}}
						</em>
						</div>
					</div>
					<label class="control-label">Password Baru <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<input type="password" v-model="form.password" :class="{'form-control': true, 'is-invalid': form.errors.has('password'),}" placeholder="Masukkan password baru" />
							<em
							v-if="form.errors.has('password')"
                            class="form-text text-danger"
						>
							{{ errors.password[0]}}
						</em>
						</div>
					</div>
                    <label class="control-label">Konfirmasi Password <span class="text-danger">*</span></label>
					<div class="row m-b-15">
						<div class="col-md-12">
							<input type="password" v-model="form.password_confirmation" :class="{'form-control': true, 'is-invalid': form.errors.has('password'),}" placeholder="konfimasi password" />
							<em
							v-if="form.errors.has('password')"
                            class="form-text text-danger"
						>
							{{ errors.password[0]}}
						</em>
						</div>
					</div>
					<div class="login-buttons">
						<button type="submit" class="btn btn-danger btn-block btn-lg">
							<span v-if="isLoading">
								<b-spinner
                                    variant="light"
                                    small
                                    label="loading"
                                ></b-spinner>
							</span>
							<i class="fa fa-paper-plane" v-else></i>
							Rubah Password
						</button>
					</div>
					<hr />
					<p class="text-center text-grey-darker">
						&copy; Pemerintah Provinsi Sumatera Utara | All Right Reserved 2020
					</p>
				</form>
			</div>
			<!-- end login-content -->
		</div>
		<!-- end right-container -->
	</div>
	<!-- end login -->
</template>

<script>
/* eslint-disable */
    // eslint-disable-next-line
    // eslint-disable vue/no-unused-vars
    // eslint-disable-line no-unused-vars

import PageOptions from '@/config/PageOptions.vue'
import Logo from '@/assets/images/logoSumut.png'

export default {
	data () {
		return {
			logoSumut: Logo,
			form: new Form({
				email: '',
				password: '',
				password_confirmation: '',
				token: this.$route.params.token
			}),
			errors: [],
			isLoading: false,
			message: ''
		}
	},
	created() {
		PageOptions.pageEmpty = true;
		document.body.className = 'bg-white';
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		document.body.className = '';
		next();
    },
    methods: {
		store() {
			this.isLoading = true
			this.form.post("/api/password/reset").then(response => {
				this.isLoading = false
				this.$swal({
					icon: 'success',
					title: 'Password Anda Berhasil Dirubah.',
					type: 'success',
					showCancelButton: false,
					showCloseButton: true,
					buttonsStyling: false,
					confirmButtonText: "Menuju Halaman Login",
					confirmButtonClass: 'btn btn-primary',
					allowOutsideClick: false,
				}).then(result => {
					if (result.isConfirmed) {
						this.$router.push({ name: 'Login'})
					}
				})
			}).catch(error => {	
				this.isLoading = false
				console.log(error)
				if (error.response.status === 422) {
					console.log(error.response)
					this.errors = error.response.data
					this.message = error.response.data.status.message
				} else if (error.response.status === 404) {
					this.$swal({
						title: 'Data Tidak Ditemukan',
						type: 'error',
						showCancelButton: true,
						buttonsStyling: false,
						confirmButtonText: "OKE",
						confirmButtonClass: 'btn btn-primary',
						allowOutsideClick: false,
						timer: 5000
					})
				}
			})
		},
        resetPassNotif(swalType) {
			var btnClass = (swalType == 'error') ? 'danger' : swalType;
				btnClass = (swalType == 'question') ? 'primary' : btnClass;
			this.$swal({
				title: 'PASSWORD BERHASIL DIRUBAH',
				type: swalType,
				showCancelButton: false,
				buttonsStyling: false,
				confirmButtonText: "Kembali ke halaman login",
				confirmButtonClass: 'btn m-r-5 btn-'+ btnClass +'',
				allowOutsideClick: false
			}).then((result) => {
				if (result.isConfirmed) {
					this.$router.push({name: 'Login'})
				}
			})
		}
    }
}
</script>